import useUserFriendlyFetch from '@/lib/ui/user-friendly-fetch';
import { useEffect, useRef, useState } from 'react';
import { useSendMessage } from './chat-app';
import FormSubmitButton from "./form-submit-button";


interface FeedbackFormProps {
  messageId?: string;
  visible: boolean;
  toggleVisibility: () => void;
}

export default function FeedbackForm({ messageId, visible, toggleVisibility }: FeedbackFormProps) {
  const sendMessage = useSendMessage();

  const [feedbackText, setFeedbackText] = useState<string>('');
  const formRef = useRef<HTMLFormElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (visible && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [visible]);

  const {isSaving, userFriendlyFetch} = useUserFriendlyFetch();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await userFriendlyFetch(async () => {
      const response = await fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedbackText, messageId }),
      });

      if (response.ok) {
        toggleVisibility();
        sendMessage(feedbackText, true);
        setFeedbackText('');
      }

      return response;
    });
  };

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus();
    }
  }, [visible]);

  return (
    <>
      {visible && (
        <form ref={formRef} onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <label htmlFor="feedback">Help us improve</label>
            <input
              id="feedback"
              ref={inputRef}
              className="border-0 bg-input-background rounded-md p-2.5 text-sm appearance-none ring-1 ring-inset ring-standard-ring placeholder:text-placeholder-text focus:ring-2 focus:ring-inset focus:ring-focused-ring"
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              placeholder="Your feedback ..."
              required
            />
            <div className="flex flex-row space-x-3">
              <FormSubmitButton caption="Submit" isDisabled={false} isSaving={isSaving} buttonType="submit" buttonColor="emerald" />
              <button type="button" onClick={toggleVisibility}>Close</button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
