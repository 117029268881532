// hooks/useWindowScrollTop.ts
import { useEffect } from 'react';

interface UseWindowScrollTopProps {
  onTopReached: () => void;
}

/**
 * A custom hook that calls `onTopReached` when `window.scrollY === 0`.
 */
export default function useWindowScrollTop({ onTopReached }: UseWindowScrollTopProps) {
  useEffect(() => {
    // If we're not in a browser environment (SSR), do nothing
    if (typeof window === 'undefined') return;

    const handleScroll = () => {
      if (window.scrollY === 0) {
        onTopReached();
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Check initial position (in case the page loads at the top)
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onTopReached]);
}
