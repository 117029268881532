'use client';

import { classNames } from '@/lib/ui/utils';
import { PaperAirplaneIcon as PaperAirplaneOutlineIcon, VariableIcon } from '@heroicons/react/24/outline';
import { PaperAirplaneIcon as PaperAirplaneSolidIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useEffect, useId, useRef, useState } from 'react';
import { ChatFeatures, useSendMessage } from './chat-app';
import FileParseForm from './file-parse-form';

type Props = {
  features: ChatFeatures,
  status: string,
  inputSize?: "normal" | "oversized",
  disableChainOfThought: boolean | null
};

export default function ChatInputWithToggle({ features, status, inputSize, disableChainOfThought }: Props) {
  const sendMessage = useSendMessage();

  const chatInputId = useId();
  const [inputText, setInputText] = useState("");
  const [useChainOfThought, setUseChainOfThought] = useState(disableChainOfThought !== null ? !disableChainOfThought : true);
  const ref = useRef<HTMLInputElement>(null);

  const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // preserve the input text in case message sending fails
    const savedInputText = inputText;
    setInputText("");
    const sent = await sendMessage(inputText, useChainOfThought);
    if (!sent) {
      setInputText(savedInputText);
    }
  }, [inputText, sendMessage, useChainOfThought]);

  const handleInput = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setInputText(event.currentTarget.value);
  }, []);

  useEffect(() => {
    if (features.autoFocusChatInput && ref && ref.current) {
      ref.current.focus();
    }
  }, [features]);

  return (
    <div className="bg-input-background rounded-md p-1">
      <form onSubmit={onSubmit}>
        <div className="relative print:hidden group">
          <input
            ref={ref}
            className={classNames(
              inputSize == "oversized" ? "h-16" : "",
              "border-0 bg-input-background rounded-md py-2.5 pl-12 pr-12 text-sm placeholder:text-secondary-text block w-full peer focus:outline-none focus:ring-0"
            )}
            id={chatInputId}
            name="chatInput"
            type="text"
            autoComplete="off"
            inputMode="search"
            value={inputText}
            onChange={handleInput}
            placeholder="Ask a question"
          />

          <div className="absolute inset-y-0 left-0 flex items-center text-tertiary-text">
            <FileParseForm />
          </div>

          <button
            type="submit"
            tabIndex={-1}
            title="Send Message"
            className="absolute inset-y-0 right-0 flex items-center px-3 cursor-pointer text-tertiary-text max-sm:active:text-standard-text hover:text-standard-text"
            onPointerDown={(e) => e.preventDefault()}
          >
            {status === "sending" &&
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-secondary-text" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            {status !== "sending" &&
              <>
                {inputText.match(/^\s*$/)
                  ? <PaperAirplaneOutlineIcon className="h-7 w-7" aria-hidden="true" />
                  : <PaperAirplaneSolidIcon className="h-7 w-7" aria-hidden="true" />
                }
              </>
            }
          </button>
        </div>
      </form>
      <button
        title="Use planning to solve reasoning problems"
        className={`flex items-center rounded-lg text-sm p-2 my-1 ${useChainOfThought ? "bg-blue-background text-colored-background-text" : ""}`}
        onClick={() => setUseChainOfThought(!useChainOfThought)}
      >
        <VariableIcon className="h-5 w-5 mr-1" />
        Think
      </button>
    </div>
  );
}
