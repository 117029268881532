import { Message } from "@/lib/conversations/message";
import { classNames } from "@/lib/ui/utils";
import { ArrowDownTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Noto_Serif } from 'next/font/google';
import ChatMessageContent from "./chat-message-content";
import CopyButton from "./copy-button";
import CopyLinkButton from "./copy-link-button";

const notoSerif = Noto_Serif({ subsets: ['latin'] });


export default function WorkingDocument({ workingDocument, workingDocumentId, openOrCloseDocument }: { workingDocument?: Message, workingDocumentId: string, openOrCloseDocument: (messageId: string) => void }) {
  return (
    <>
      <div className="sticky top-0 bg-page-background w-full max-lg:pb-2 lg:p-2 border-b border-standard-ring">
        <div className="flex flex-row items-center justify-between">
          <div className="text-base font-semibold truncate">
            {workingDocument?.metadata.fileName}
          </div>
          <button
            type="button"
            onClick={() => openOrCloseDocument(workingDocumentId)}
            className="text-secondary-text hover:text-standard-text transition-colors ml-2"
          >
            <XMarkIcon aria-hidden="true" className="size-6" />
          </button>
        </div>
        <div className="mt-2 flex flex-row gap-5 items-center">
          <form action={`/api/myfiles/${workingDocumentId}/download`} method="post">
            <button
              type="submit"
              disabled={workingDocument?.metadata.status && workingDocument.metadata.status != 'done'}
              className="flex items-center gap-1 text-secondary-text hover:text-standard-text disabled:text-tertiary-text text-sm"
            >
              <ArrowDownTrayIcon aria-hidden="true" className="size-5" />
              <span>Download</span>
            </button>
          </form>
          <div className="text-sm">
            <CopyButton text={workingDocument?.content || ''} />
          </div>
          <div className="text-sm">
            <CopyLinkButton />
          </div>
        </div>
      </div>
      <div className={classNames(
        notoSerif.className,
        "prose dark:prose-invert prose-h1:text-2xl prose-pre:bg-page-background prose-pre:text-standard-text prose-pre:whitespace-pre-wrap prose-pre:whitespace-break-spaces",
      )}>
        <ChatMessageContent content={workingDocument?.content} />
      </div>
    </>
  );
}
