import { trackBrowserEvent } from "@/lib/events/track-browser-event";
import { TrackingEvent } from "@/lib/events/track-event";
import { NotificationType, showNotification } from "@/lib/ui/notifications";
import { CheckIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { useState } from "react";

interface CopyButtonProps {
  text: string;
}

export default function CopyButton({ text }: CopyButtonProps) {

  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      navigator.clipboard.writeText(text);
      showNotification({ type: NotificationType.Success, title: 'Success', description: 'Text copied to clipboard!' });
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Could not copy text: ', err);
    }
    trackBrowserEvent(TrackingEvent.CopyText, {});
  };

  return (
    <div onClick={copyToClipboard} title="Copy text" className="flex items-center space-x-1 cursor-pointer text-secondary-text hover:text-standard-text">
      {copied ? (
        <CheckIcon className="h-5 w-5 text-green-text" />
      ) : (
        <ClipboardDocumentIcon className="h-5 w-5" />
      )}
      <span>{copied ? 'Copied' : 'Copy'}</span>
    </div >
  );
}
