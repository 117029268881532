import { NotificationType, showNotification } from '@/lib/ui/notifications';
import { CheckIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';


interface ThumbsUpButtonProps {
  messageId: string | undefined
}

export default function ThumbsUpButton({ messageId }: ThumbsUpButtonProps) {

  const [clicked, setClicked] = useState(false);

  const handleClick = async () => {
    showNotification({ type: NotificationType.Success, title: 'Success', description: 'Feedback sent.' });
    setClicked(true);

    await fetch('/api/feedback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ feedbackText: "thumbs-up", messageId }),
    });
  };

  return (
    <div onClick={handleClick} title="Good response" className="flex items-center space-x-1 cursor-pointer text-secondary-text hover:text-standard-text">
      {clicked && <CheckIcon className="h-5 w-5 text-green-text" />}
      <HandThumbUpIcon className="h-5 w-5" />
    </div >
  );
}
