'use client';

import { NewMessage } from "@/lib/conversations/message";
import ChatMessageContent from "./chat-message-content";
import { useState } from "react";

export default function ChatMessageUsageGuide({message}: { message: NewMessage }) {
  const [guideVisible, setGuideVisible] = useState<boolean>(false);

  return (
    <>
      <div className="max-sm:hidden">
        <ChatMessageContent content={message.content} />
      </div>
      <div className="sm:hidden">
        { guideVisible && <ChatMessageContent content={message.content} /> }
        { !guideVisible &&
          <>
            <div className="line-clamp-2">
              {message.content}
            </div>
            <a
              onClick={ (e) => setGuideVisible(true) }
              className="block w-full text-center my-1 py-2 hover:underline cursor-pointer rounded-full shadow-sm ring-1 ring-inset ring-standard-ring"
            >
              Learn more
            </a>
          </>
        }
      </div>
    </>
  );
}
