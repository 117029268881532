import Image from "next/image";
import { Expert } from "@/lib/experts/expert";
import { ReactNode } from "react";
import { classNames } from "@/lib/ui/utils";
import { parsePhoneNumber } from "libphonenumber-js";
import { H } from "highlight.run";
import { SocialLinks } from 'social-links';
import { SocialIcon } from "react-social-icons";
import { PhoneIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

export type ExpertCardFeatures = {
  layout: "horizontal" | "vertical",
  size: "compact" | "standard",
}

type Props = {
  expert: Expert,
  children?: ReactNode,
}

export default function ExpertCard({layout, size, expert, children}: Props & ExpertCardFeatures) {

  const expertFullName = [expert.firstName, expert.lastName].join(' ');
  const flexDirection = layout == 'vertical' ? 'col' : 'row space-x-5';
  let parsedPhoneNumber = '';
  if (expert.phoneNumber) {
    try {
      parsedPhoneNumber = parsePhoneNumber(expert.phoneNumber ?? '', 'US').formatNational();
    } catch(error) {
      if (error instanceof Error) H.consumeError(error);
    }
  }

  const socialLinks = new SocialLinks({
    usePredefinedProfiles: true,
    trimInput: true,
    allowQueryParams: false,
  });
  const linksWithMetadata = [ expert.link1, expert.link2, expert.link3, expert.link4 ].map( (link) => {
    if (!link) {
      return { link: undefined };
    }
    const profileName = socialLinks.detectProfile(link);
    if (!socialLinks.isValid(profileName, link)) {
      return { link };
    }
    return {
      link,
      profileName,
      profileId: socialLinks.getProfileId(profileName, link),
    };
  });

  return (
    <>
      <div className={`p-3 border border-gray-300 rounded-2xl flex flex-${flexDirection} ${layout == 'vertical' ? 'items-center' : 'items-start'}`}>
        <div className={classNames(
          layout == 'vertical' ? "w-40 h-40" : ( size == "standard" ? "w-20 h-20" : "w-7 h-7" ),
          "relative overflow-hidden flex-none"
        )}>
          {/*
            - sizes property requests a small image to override the default which is 100% of viewport
            - fill + object-cover here and relative + overflow-hidden on the parent make an image show up in the right place
           */}
          <Image className="rounded-full object-cover" sizes="10rem" priority={true} src={expert.photoUrl!} alt={expert.firstName} fill={true} />
        </div>
        <div className={classNames(
          "flex flex-col",
          layout == "vertical" ? "items-center pt-3" : "w-full",
        )}>
          <div className="text-base font-semibold leading-7 tracking-tight text-gray-600">{expertFullName}</div>
          { size == "standard" &&
            <>
              <div className={classNames(
                "line-clamp-3 text-xs leading-6 text-gray-500",
                layout == 'vertical' ? 'text-center' : '',
              )}>
                {expert.tagline}
              </div>
              <div className="text-xs leading-6 text-gray-500">{expert.location}</div>
              { layout == 'horizontal' && (
                <div className="text-xs leading-6 text-gray-500 grid grid-cols-1 sm:grid-cols-2 justify-stretch">
                  {expert.email && (
                    <div className="flex flex-row justify-items-center">
                      <EnvelopeIcon className="w-[18px] h-[18px] flex-none my-auto mr-1" />
                      <a className="hover:underline whitespace-nowrap text-ellipsis overflow-hidden" target="_blank" href={`mailto:${expert.email}`}>
                        {expert.email}
                      </a>
                    </div>
                  )}
                  {parsedPhoneNumber && (
                    <div className="flex flex-row justify-items-center">
                      <PhoneIcon className="w-[18px] h-[18px] flex-none my-auto mr-1" />
                      <a className="hover:underline" target="_blank" href={`tel:${parsedPhoneNumber}`}>
                        {parsedPhoneNumber}
                      </a>
                    </div>
                  )}
                  {
                    linksWithMetadata.filter( (link) => !!link.link ).map( (link, index) => (
                      <div key={index} className="flex flex-row justify-items-center">
                        <SocialIcon target="_blank" bgColor="#6b7280" network={link.profileName} url={link.link} style={{ height: 20, width: 20 }} className="flex-none my-auto mr-1" />
                        <a className="hover:underline whitespace-nowrap text-ellipsis overflow-hidden" target="_blank" href={link.link}>
                          {link.profileId ?? link.link}
                        </a>
                      </div>
                    ))
                  }
                </div>
              )}
            </>
          }
        </div>
        {children}
      </div>
    </>
  );

}
