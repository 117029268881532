'use client';

import { ChatMessagesContext } from '@/app/_components/conversation-state-provider';
import { Conversation, NUMBER_OF_MESSAGES_TO_LOAD, SharedConversation } from '@/lib/conversations/conversation';
import { Message, MessageRole } from '@/lib/conversations/message';
import { UsageHints } from '@/lib/conversations/usage-hints';
import { trackBrowserEvent } from '@/lib/events/track-browser-event';
import { TrackingEvent } from "@/lib/events/track-event";
import { NotificationType, showNotification, uiEventsBus } from '@/lib/ui/notifications';
import { useIsDesktop } from '@/lib/ui/use-is-desktop';
import useWindowScrollTop from '@/lib/ui/use-scroll-to-top';
import { classNames } from '@/lib/ui/utils';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { H } from '@highlight-run/next/client';
import { useRouter, useSearchParams } from 'next/navigation';
import { Context, createContext, ReactNode, SetStateAction, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ChatInputWithToggle from './chat-input-with-toggle';
import ChatMessage from './chat-message';
import ExpertCard from './expert-card';
import { FileAttachment } from './file-attachment';
import { MyFile } from './file-parse-form';
import SmallButton from './small-button';
import WorkingDocument from './working-document';


type SendMessageFunction = (message: string, useChainOfThought: boolean) => Promise<boolean>;

const SendMessageContext: Context<SendMessageFunction> = createContext(async (message: string, useChainOfThought: boolean) => { return new Promise<boolean>((resolve) => resolve(false)); });

// share files state between ChatApp and FileParseForm
export const FilesContext = createContext<{ files: MyFile[], setFiles: React.Dispatch<SetStateAction<MyFile[]>> } | null>(null);

export const SendMessageProvider = ({ children, sendMessage }: { children: ReactNode, sendMessage: SendMessageFunction }) => {
  return (
    <SendMessageContext.Provider value={sendMessage}>
      {children}
    </SendMessageContext.Provider>
  );
};

export const useSendMessage = (): SendMessageFunction => {
  const context = useContext(SendMessageContext);
  if (!context) {
    throw new Error("useSendMessage must be used within a SendMessageProvider");
  }
  return context;
};


export type WorkingDocumentContextType = {
  workingDocumentOpen?: boolean,
  setWorkingDocumentOpen: React.Dispatch<SetStateAction<boolean>>,
  workingDocumentId?: string,
  setWorkingDocumentId: React.Dispatch<SetStateAction<string | undefined>>,
}

const WorkingDocumentContext = createContext<WorkingDocumentContextType>({
  workingDocumentOpen: undefined,
  setWorkingDocumentOpen: () => { },
  workingDocumentId: undefined,
  setWorkingDocumentId: () => { }
});

export const useWorkingDocument = () => {
  const context = useContext(WorkingDocumentContext);
  if (!context) {
    throw new Error("useWorkingDocument must be used within a WorkingDocumentContext");
  }
  return context;
};


export type ChatFeatures = {
  // if true, it appends chat ID or human name to the url as soon as we know it
  updatePageUrl: boolean,
  // if true, it focuses chat input as soon as chat is rendered
  autoFocusChatInput: boolean,
  // if true, shows expert card header
  hasExpertCard: boolean,
  // if true, shows the disclaimer "Cetient is not a law firm..."
  showFooter: boolean,
  inputOnlyMode: boolean,
};

export const DefaultChatFeatures: ChatFeatures = {
  updatePageUrl: true,
  autoFocusChatInput: true,
  hasExpertCard: false,
  showFooter: true,
  inputOnlyMode: false,
};

type Props = {
  conversation: Conversation,
  features: ChatFeatures,
  usageHints?: UsageHints,
  subheader: string
}

export default function ChatApp({ conversation, features, usageHints, subheader }: Props) {
  const [conversationId, setConversationId] = useState<string>(conversation.id);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sharedConversation, setSharedConversation] = useState<SharedConversation | undefined>(conversation.sharedConversation);
  const ref = useRef<HTMLDivElement>(null);

  const conversationsContext = useContext(ChatMessagesContext);
  const { messages } = conversationsContext.useConversationState(conversationId, conversation.messages);

  const prevScrollHeightRef = useRef(0);
  const prevScrollYRef = useRef(0);
  const isLoadingRef = useRef(false);
  const oldestTimestamp = useRef(
    messages.length ? messages[messages.length - 1].createdAt : null);
  const [hasMore, setHasMore] = useState(conversation.messages.length >= NUMBER_OF_MESSAGES_TO_LOAD);
  const autoScrollRef = useRef<boolean>(true);
  const [status, setStatus] = useState<string>('');

  // drag and drop
  const [dragging, setDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);

  const expert = conversation.expert;

  const searchParams = useSearchParams();
  const query = useRef<string | null>(searchParams.get('q'));
  const disableChainOfThought = useRef<boolean | null>(searchParams.get('disableChainOfThought') === 'true');

  const [files, setFiles] = useState<MyFile[]>([]);

  const [workingDocumentOpen, setWorkingDocumentOpen] = useState<boolean>(false);
  const [workingDocumentId, setWorkingDocumentId] = useState<string | undefined>(undefined);

  const router = useRouter();

  const loadMoreMessages = useCallback(async () => {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;
    if (!oldestTimestamp.current) return;
    if (!hasMore) return;

    try {
      const response = await fetch(`/api/conversations/${conversationId}/messages`, {
        method: "POST",
        body: JSON.stringify({ timestamp: oldestTimestamp.current })
      });
      const { messages: olderMessages } = await response.json();

      // If the server returned no messages, we've reached the oldest possible.
      if (!olderMessages || olderMessages.length === 0) {
        setHasMore(false);
        isLoadingRef.current = false;
        return;
      }

      prevScrollHeightRef.current = document.documentElement.scrollHeight;
      prevScrollYRef.current = window.scrollY;

      // The server returns older messages in DESC order as well (newest->oldest).
      // Since these older ones belong "above" in the chat, we append them to the *end* of the array
      // in state. The visual flip comes from CSS (column-reverse).
      const { setMessages } = conversationsContext.useConversationState(conversationId, conversation.messages);
      setMessages((prev) => [...prev, ...olderMessages]);

      const lastMsg = olderMessages[olderMessages.length - 1];
      oldestTimestamp.current = lastMsg.createdAt;
    } catch (err) {
      console.error('Error loading older messages:', err);
      setHasMore(false);
      isLoadingRef.current = false;
    }
  }, [conversationId, hasMore, conversation.messages, conversationsContext]);

  useLayoutEffect(() => {
    if (isLoadingRef.current) {
      requestAnimationFrame(() => {
        const newScrollHeight = document.documentElement.scrollHeight;
        const scrollDifference = newScrollHeight - prevScrollHeightRef.current;
        // we're not sure why we'll get here without setting prevScrollHeightRef.current
        // but when it happens, we don't want to scroll because we'd just jump to the bottom
        if (prevScrollHeightRef.current > 0)
          window.scrollTo(0, prevScrollYRef.current + scrollDifference);
        prevScrollHeightRef.current = 0;
        prevScrollYRef.current = 0;
        isLoadingRef.current = false;
      });
    }
  }, [messages]);

  const copyToClipboard = async () => {
    try {
      const url = new URL(window.location.href);
      navigator.clipboard.writeText(url.toString());
      showNotification({ type: NotificationType.Success, title: 'Success', description: 'Link copied to clipboard!' });
    } catch (err) {
      console.error('Could not copy url: ', err);
    }
    trackBrowserEvent(TrackingEvent.ShareUrl, {});
  };

  const scrollToInput = useCallback((behavior: ScrollBehavior) => {
    if (ref && ref.current && autoScrollRef.current) {
      ref.current.scrollIntoView({
        behavior,
        block: "start",
      });
    }
  }, []);

  useEffect(() => {
    const handleUserEvent = () => {
      autoScrollRef.current = false;
    };

    window.addEventListener('keydown', handleUserEvent);
    window.addEventListener('mousedown', handleUserEvent);
    window.addEventListener('wheel', handleUserEvent);
    window.addEventListener('touchstart', handleUserEvent);

    return () => {
      window.removeEventListener('keydown', handleUserEvent);
      window.removeEventListener('mousedown', handleUserEvent);
      window.removeEventListener('wheel', handleUserEvent);
      window.removeEventListener('touchstart', handleUserEvent);
    };
  }, []);

  useEffect(() => {
    scrollToInput("smooth");
  }, [messages, scrollToInput]);

  useEffect(() => {
    if (
      conversationId
      && features.updatePageUrl
      // this prevents changing human readable URL in the initial render
      // useEffect hook will be called when rendering, and we just need to skip it if conversation id never changed
      && conversation.id !== conversationId
    ) {
      const url = new URL(window.location.href);
      const pathSegments = url.pathname.split("/");
      // warning! this assumes /<expert url>/<chat url> URLs with 3 segments: ["", "<expert url>", "<chat url>"]
      // if you change routing, you need to change this code too
      // adymo: I haven't found a good way to give a warning about this
      if (pathSegments.length == 3) {
        pathSegments.pop();
      }
      pathSegments.push(conversationId);
      url.pathname = pathSegments.join("/");
      history.replaceState(null, "", url);
      // conversation id changes in two cases:
      // 1. new conversation is created
      // 2. shared conversation is cloned
      // in the second case we never reload conversation object, so some of its preloaded state needs to be cleaned up
      // TODO: shall we just router.refresh() ?
      conversation.sharedConversation = undefined;
      conversation.readOnly = false;
      setSharedConversation(undefined);
    }
  }, [conversationId, conversation, features.updatePageUrl]);

  const onBeginStream = useCallback((message: Message) => {
    if (message?.metadata?.tool == "myfiles" && message?.metadata?.author == "cetient" && message?.metadata?.status == "in-progress") {
      setWorkingDocumentOpen(true);
      setWorkingDocumentId(message.id);

      const url = new URL(window.location.href);
      url.hash = `file-${message.id}`;
      history.replaceState(null, "", url);
    }
  }, [setWorkingDocumentOpen, setWorkingDocumentId]);

  const sendMessage = useCallback(async (messageContent: string, useChainOfThought: boolean): Promise<boolean> => {
    // don't allow empty messages unless there is a file attached
    if (!messageContent || messageContent.match(/^\s+$/)) {
      if (files.filter(f => f.status !== 'failed').length == 0) {
        return false;
      }
      messageContent = files.length == 1 ? "File attached" : `${files.length} files attached`;
    }
    if (messageContent.length > 100000) {
      showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: "Message too long!" });
      return false;
    }

    if (files.some(f => f.status === 'uploading')) {
      showNotification({ type: NotificationType.Error, title: 'Please wait', description: "File is still uploading!" });
      return false;
    }

    // eslint-disable-next-line prefer-const
    let { setMessages, cloneMessagesTo } = conversationsContext.useConversationState(conversationId, conversation.messages);

    const newMessage = {
      id: uuidv4(),
      content: messageContent,
      role: MessageRole.user,
    };
    autoScrollRef.current = true;
    setMessages((prevMessages) => [newMessage as Message, ...prevMessages]);
    setStatus("sending");

    const metadata: {
      fileIds?: string[],
      useChainOfThought?: boolean
    } = {};
    if (files.length > 0) {
      metadata.fileIds = files.map(f => f.id);
      setFiles([]);
    }

    metadata.useChainOfThought = useChainOfThought;

    try {
      const response = await fetch("/api/messages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Url": window.location.href,
          "X-Referrer": document.referrer,
        },
        body: JSON.stringify({
          conversationId: conversationId,
          expertId: expert.id,
          message: {
            content: messageContent,
            role: MessageRole.user,
            metadata
          },
        }),
      });

      if (!response.ok || !response.body) {
        showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: response.statusText });
        setStatus("");
        return false;
      }

      const [serverConversationId, userMessageId] =
        ["Cetient-Conversation-Id", "Cetient-User-Message-Id"].map((header) => {
          const value = response.headers.get(header);
          if (!value) throw new Error(`${header} is not passed`);
          return value;
        });

      if (serverConversationId != conversationId) {
        setConversationId(serverConversationId);

        cloneMessagesTo(serverConversationId);
        ({ setMessages } = conversationsContext.useConversationState(serverConversationId, conversation.messages));

        uiEventsBus.emit("conversationCreatedOrCloned", { conversationId: serverConversationId, userMessage: messageContent });
      }

      newMessage.id = userMessageId;
      setMessages((prevMessages) => prevMessages);

      setStatus("");

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      let lastMessage;

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        buffer += decoder.decode(value, { stream: true });

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data:')) {
            const data = line.slice(5).trim();
            const newMessage = JSON.parse(data);

            if (newMessage.id != lastMessage?.id) {
              lastMessage = newMessage;
              onBeginStream(newMessage);
            }

            setMessages((messageHistory) => {
              const filtered = messageHistory.filter((msg) => msg.id !== newMessage.id);
              return [newMessage, ...filtered];
            });
          }
        }
      }
    }
    catch (error) {
      if (error instanceof Error) H.consumeError(error);
      console.log(error);

      // if something happened during the streaming, the client is likely to end up in a bad state
      // server may have a better state with "in progress" status reset and abort message created
      // so refresh the server state and rerender our messages using that
      router.refresh();
      setMessages(prev => conversation.messages);
      setStatus("");

      showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: 'Please try again' });

      return false;
    }
    return true;
  }, [files, conversationsContext, conversationId, conversation.messages, expert.id, onBeginStream, router]);

  useEffect(() => {
    if (query.current && messages.length == 0) {
      sendMessage(query.current, disableChainOfThought.current ? false : true);
      query.current = null;
      disableChainOfThought.current = null;
    }
  }, [messages.length, query, sendMessage]);

  useWindowScrollTop({ onTopReached: loadMoreMessages });

  // drag and drop
  const handleDragEnter = useCallback((e: React.DragEvent) => {
    if (dropRef.current && dropRef.current.contains(e.target as Node)) {
      e.preventDefault();
      e.stopPropagation();
      setDragging(true);
    }
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    if (dropRef.current && !dropRef.current.contains(e.relatedTarget as Node)) {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
    }
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const selectedFiles: File[] = Array.from(e.dataTransfer.files);
    if (selectedFiles.length > 0) {
      const newFileObjects: MyFile[] = selectedFiles.map(file => ({
        file,
        id: `${file.name}-${file.size}-${Date.now()}`,
        status: 'pending',
      }));
      setFiles(prev => [...prev, ...newFileObjects]);
    }
  }, []);

  const handleDragStart = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.clearData();
  }, []);

  const handleFileDelete = (index: number) => {
    setFiles(prev => prev.filter((_, i) => i !== index));
  };

  const previousTopRef = useRef<number | null>(null);

  const openOrCloseDocument = useCallback((messageId: string) => {
    const el = document.getElementById(messageId);
    if (!el) return;

    const { top } = el.getBoundingClientRect();
    previousTopRef.current = top;

    let isOpen;

    if (workingDocumentId == messageId) {
      isOpen = !workingDocumentOpen;
    } else {
      isOpen = true;
      setWorkingDocumentId(messageId);
    }

    setWorkingDocumentOpen(isOpen);

    const url = new URL(window.location.href);
    if (isOpen) {
      url.hash = `file-${messageId}`;
    } else {
      url.hash = '';
    }
    history.replaceState(null, "", url);

  }, [workingDocumentId, workingDocumentOpen, setWorkingDocumentId, setWorkingDocumentOpen]);

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (!workingDocumentId || !previousTopRef.current)
        return;

      const el = document.getElementById(workingDocumentId);
      if (el) {
        const { top: newTop } = el.getBoundingClientRect();
        const oldTop = previousTopRef.current || 0;

        // The difference between newTop and oldTop is how much
        // the element has shifted after expansion/shrink
        const diff = newTop - oldTop;

        // Scroll by the difference to keep the element in the same
        // position relative to the viewport.
        window.scrollBy(0, diff);
      }

      previousTopRef.current = null;
    });
  }, [workingDocumentOpen, workingDocumentId]);

  // check if the url contains a file hash and open the file
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash;
      if (hash.startsWith('#file-')) {
        const fileId = hash.replace('#file-', '');
        setWorkingDocumentId(fileId);
        setWorkingDocumentOpen(true);
      }
    }
  }, []);

  const isDesktop = useIsDesktop();

  const workingDocument = messages.find(msg => msg.id == workingDocumentId);

  // setting translate=no to solve crashes related to google translate in chrome
  // see https://github.com/facebook/react/issues/11538#issuecomment-390386520
  // the crash happens when we try to remove a message to replace it with a newer one
  // but chrome already replaced it with a translation
  // anyway we don't really want user and ai generated content to be translated
  return (
    <SendMessageProvider sendMessage={sendMessage}>
      <WorkingDocumentContext.Provider value={{ workingDocumentOpen, setWorkingDocumentOpen, workingDocumentId, setWorkingDocumentId }}>

        <div
          ref={dropRef}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragStart={handleDragStart}
          translate="no"
          className={classNames(
            "grow flex",
            workingDocumentOpen ? "gap-4" : "w-full sm:max-w-2xl xl:max-w-4xl mx-auto",
          )}
        >

          {dragging && (
            <div className="fixed z-10 inset-0 flex items-center justify-center bg-page-background/75">
              <div className="bg-input-background ring-1 ring-standard-ring p-4 rounded-lg">
                <p className="text-center text-standard-text">Drop files here</p>
              </div>
            </div>
          )}

          {workingDocumentOpen && workingDocumentId && (
            <>
              <div className={classNames(
                "max-lg:hidden sticky right-0",
                // if news banner is visible, working document should appear below it and be shorter
                // group/layout is defined in (product)/layout and news-banner class element is only there if news are not dismissed
                "top-2 group-has-[.news-banner]/layout:top-14 h-[calc(100vh-1rem)] group-has-[.news-banner]/layout:h-[calc(100vh-4rem)]",
                "grow flex flex-col items-center overflow-y-auto",
              )}>
                <WorkingDocument workingDocument={workingDocument} workingDocumentId={workingDocumentId} openOrCloseDocument={openOrCloseDocument} />
              </div>

              <div className="lg:hidden">
                <Dialog open={!isDesktop} onClose={() => { openOrCloseDocument(workingDocumentId); }} className="lg:hidden relative z-10">
                  <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                  />

                  <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center p-4 text-center">
                      <DialogPanel
                        transition
                        className="grow relative transform min-h-full overflow-hidden rounded-lg bg-page-background px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <WorkingDocument workingDocument={workingDocument} workingDocumentId={workingDocumentId} openOrCloseDocument={openOrCloseDocument} />
                      </DialogPanel>
                    </div>
                  </div>
                </Dialog>

              </div>
            </>
          )}

          <div className={classNames(
            "flex flex-col",
            workingDocumentOpen ? "w-96" : "grow",
          )}>
            {(messages.length == 0 || features.inputOnlyMode) && (
              <div className="w-full my-auto">
                <h1 className="mb-5 text-center text-lg sm:text-2xl lg:text-3xl tracking-tight">
                  {subheader}
                </h1>

                <FilesContext.Provider value={{ files, setFiles }}>
                  <ChatInputWithToggle features={features} inputSize="oversized" status={status} disableChainOfThought={disableChainOfThought.current} />
                  <div className="flex flex-row flex-wrap gap-x-2">
                    {files.map((f, index) => (
                      <FileAttachment
                        key={f.id}
                        file={f}
                        onDelete={() => handleFileDelete(index)}
                      />
                    ))}
                  </div>
                </FilesContext.Provider>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-4 text-secondary-text w-full">
                  {usageHints?.map(({ category, question }) => (
                    <a
                      href="#"
                      key={category}
                      className="ring-1 ring-inset ring-secondary-ring rounded-md text-sm hover:bg-list-item-hover p-2 block"
                      onClick={(e) => { sendMessage(question, true); e.preventDefault(); e.stopPropagation(); }}
                    >
                      {question}
                    </a>
                  ))}
                </div>
                {features.showFooter && (
                  <div className="my-2 mx-8 text-sm font-semibold text-secondary-text">
                    <p className="text-center">
                      Cetient is not a law firm and does not provide legal advice. It can make mistakes. Check important info.
                    </p>
                  </div>
                )}
              </div>
            )}

            {messages.length > 0 && !features.inputOnlyMode && (
              <>
                <div key="chat-messages" className="grow flex flex-col justify-end">
                  {hasMore && <div className="text-secondary-text animate-pulse">Loading messages...</div>}

                  <div className="mb-2 flex flex-row space-x-5">
                    <div className="flex-1">
                      {features.hasExpertCard &&
                        <ExpertCard layout="horizontal" size="standard" expert={expert}>
                          <div className="flex flex-col space-y-3">
                            <SmallButton caption="Share" onClick={copyToClipboard} />
                          </div>
                        </ExpertCard>
                      }
                    </div>
                  </div>

                  <div className="flex flex-col-reverse items-end [content-visibility:auto] [contain-intrinsic-size:300px]">
                    {messages.map((message) => (
                      <ChatMessage key={message.id} message={message} openOrCloseDocument={openOrCloseDocument} />
                    ))}
                  </div>

                </div>

                {!conversation.readOnly && (
                  <>
                    <div className="sticky print:block bottom-0 flex flex-col items-center mt-2 bg-page-background">
                      <div className="w-full">
                        <FilesContext.Provider value={{ files, setFiles }}>
                          <ChatInputWithToggle features={features} status={status} disableChainOfThought={disableChainOfThought.current} />
                          <div className="flex flex-row flex-wrap gap-x-2">
                            {files.map((f, index) => (
                              <FileAttachment
                                key={f.id}
                                file={f}
                                onDelete={() => handleFileDelete(index)}
                              />
                            ))}
                          </div>
                        </FilesContext.Provider>
                      </div>
                      <div className="my-2 mx-8 text-xs text-secondary-text">
                        <p className="text-center">
                          Cetient is not a law firm and does not provide legal advice. It can make mistakes. Check important info.
                        </p>
                      </div>
                    </div>
                    <div ref={ref}></div>
                  </>
                )}
              </>
            )}
          </div>

        </div>
      </WorkingDocumentContext.Provider>
    </SendMessageProvider>
  );
}
