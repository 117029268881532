import { HandThumbDownIcon } from '@heroicons/react/24/outline';
import { MouseEvent } from 'react';


interface ThumbsDownButtonProps {
  messageId: string | undefined
  onClick: () => void
}

const handleClick = (messageId: string | undefined, onClick: () => void) =>
  async (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();  // Prevent default behavior if needed
    onClick();

    await fetch('/api/feedback', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ feedbackText: "thumbs-down", messageId }),
    });
  };

export default function ThumbsDownButton({ messageId, onClick }: ThumbsDownButtonProps) {

  return (
    <div onClick={handleClick(messageId, onClick)} title="Bad response" className="flex items-center space-x-1 cursor-pointer text-secondary-text hover:text-standard-text">
      <HandThumbDownIcon className="h-5 w-5" />
    </div >
  );
}
