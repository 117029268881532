import { useEffect, useState } from "react";

export function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    function checkScreen() {
      setIsDesktop(window.innerWidth >= 1024); // 1024px is Tailwind's 'lg' breakpoint by default
    }
    checkScreen();
    window.addEventListener('resize', checkScreen);
    return () => window.removeEventListener('resize', checkScreen);
  }, []);

  return isDesktop;
}
