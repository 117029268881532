'use client';

import { Message } from '@/lib/conversations/message';
import { createContext, ReactNode, useCallback, useState } from 'react';


export const ChatMessagesContext = createContext<{
  useConversationState: (key: string, defaultValue: Message[]) => {
    messages: Message[],
    setMessages: (newValueSetter: (previous: Message[]) => Message[]) => void,
    cloneMessagesTo: (newKey: string) => void
  };
}>({ useConversationState: () => { return { messages: [], setMessages: () => {}, cloneMessagesTo: ()=>{} }; } });


export default function ConversationStateProvider({ children }: { children: ReactNode }) {
  const [conversations, setConversations] = useState<Record<string, Message[]>>({});

  const useConversationState = useCallback( (conversationId: string, defaultValue: Message[]) => {
    return {
      messages: conversations[conversationId] || defaultValue,

      setMessages: (newValueSetter: (previous: Message[]) => Message[]) => {
        setConversations( state => {
          return ({ ...state, [conversationId]: newValueSetter(state[conversationId] || defaultValue) });
        });
      },

      cloneMessagesTo: (newConversationId: string) => {
        setConversations( state => {
          const newState = { ...state };
          newState[newConversationId] = newState[conversationId];
          delete newState[conversationId];
          return newState;
        });
      }
     };
  }, [conversations]);

  return (
    <ChatMessagesContext.Provider value={ { useConversationState } }>
      {children}
    </ChatMessagesContext.Provider>
  );
}
