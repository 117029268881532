import { PaperClipIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext, useEffect } from 'react';
import { FilesContext } from './chat-app';
import './thinking.css';
import { NotificationType, showNotification } from '@/lib/ui/notifications';

// TODO: dark / light mode
// TODO: maybe have a timeout for file parsing
// TODO: display the file attachments in chat
// TODO: fix: enter key while uploading erases the input
// TODO: how many files should we allow?

export interface MyFile {
  id: string,
  file: File
  error?: string,
  status: 'pending' | 'uploading' | 'uploaded' | 'failed',
  progress?: string,
};

// This component handles files passed in via context or via the file input
// it immediately beings uploading them and saves the returned id
export default function FileParseForm() {

  const filesContext = useContext(FilesContext);
  if (!filesContext) {
    throw new Error("filesContext is null!");
  }
  const { files, setFiles } = filesContext;

  // Handle selection of new files via <input type="file" />
  interface FileChangeEvent extends React.ChangeEvent<HTMLInputElement> {
    target: HTMLInputElement & { files: FileList };
  }

  const handleFileChange = (e: FileChangeEvent) => {
    const selectedFiles: File[] = Array.from(e.target.files);
    if (!selectedFiles.length) return;

    const newFileObjects: MyFile[] = selectedFiles.map(file => ({
      file,
      id: `${file.name}-${file.size}-${Date.now()}`,
      status: 'pending'
    }));

    setFiles(prev => [...prev, ...newFileObjects]);

    // Immediately begin uploading the newly added files
    newFileObjects.forEach(uploadFile);

    // Reset the input value to allow selecting the same file again
    e.target.value = '';
  };

  const uploadFile = useCallback(async (fileObj: MyFile) => {
    try {
      setFiles((prev) => prev.map((f) =>
        f.id === fileObj.id ? { ...f, status: "uploading", progress: "Preparing" } : f));

      const formData = new FormData();
      formData.append("file", fileObj.file);

      const response = await fetch("/api/parse-file", {
        method: "POST",
        body: formData,
      });

      if (!response.ok || !response.body) {
        showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: response.statusText });

        setFiles((prev) => prev.map((f) =>
          f.id === fileObj.id ? { ...f, status: "failed", error: response.statusText || 'Failed to parse file' } : f
        ));

        return;
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        buffer += decoder.decode(value, { stream: true });

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data:')) {
            const data = line.slice(5).trim();

            setFiles((prev) => prev.map((f) =>
              f.id === fileObj.id ? { ...f, status: 'uploading', progress: data || '' } : f
            ));
          }
          if (line.startsWith("id:")) {
            setFiles((prev) => prev.map((f) =>
              f.id === fileObj.id ? { ...f, status: 'uploaded', id: line.slice(3).trim() } : f
            ));
          }
        }
      }

    } catch (error: any) {
      showNotification({ type: NotificationType.Error, title: 'Something went wrong', description: error.message || 'Failed to parse file' });
      setFiles((prev) => prev.map((f) =>
        f.id === fileObj.id ? { ...f, status: "failed", error:  (error.message || 'Failed to parse file') } : f
      ));
    }
  }, [setFiles]);

  useEffect(() => {
    // Upload all files whose status is "pending"
    files.forEach((f) => {
      if (f.status === "pending") {
        uploadFile(f);
      }
    });
  }, [files, uploadFile]);

  return (
    <div className="flex items-center">
      <input type="file" onChange={handleFileChange} accept="*" multiple id="file-upload" className="hidden" />
      <label htmlFor="file-upload" title="Attach file"
        className="cursor-pointer flex items-center justify-center m-1 w-8 h-8 rounded-md text-secondary-text hover:text-standard-text">
        <PaperClipIcon className="w-7 h-7" />
      </label>
    </div>
  );
};
