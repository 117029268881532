'use client';

type Props = {
  isSaving: boolean,
  isDisabled: boolean,
  caption: string,
  buttonType?: "submit" | "button" | "reset",
  buttonColor?: "emerald" | "indigo",
  className?: string,
}

export default function FormSubmitButton(
{
  isSaving,
  isDisabled,
  caption,
  buttonType = "submit",
  buttonColor = "indigo",
  className,
}: Props) {

  return (
    <button
      type={buttonType}
      disabled={isDisabled}
      className={`
        ${className}
        inline-flex px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm whitespace-nowrap
        bg-${buttonColor}-600 disabled:bg-gray-500
        hover:bg-${buttonColor}-500 hover:disabled:bg-gray-400
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${buttonColor}-600
      `}
    >
      {isSaving && (
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
      { caption }
    </button>
  );
}
