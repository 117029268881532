import { trackBrowserEvent } from "@/lib/events/track-browser-event";
import { TrackingEvent } from "@/lib/events/track-event";
import { NotificationType, showNotification } from "@/lib/ui/notifications";
import { CheckIcon, LinkIcon } from '@heroicons/react/24/outline';
import { useState } from "react";


export default function CopyLinkButton() {

  const [copied, setCopied] = useState(false);

  const copyLinkToClipboard = () => {
    try {
      const url = new URL(window.location.href);
      navigator.clipboard.writeText(url.toString());
      showNotification({ type: NotificationType.Success, title: 'Success', description: 'Link copied to clipboard!' });
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Could not copy link: ', err);
    }
    trackBrowserEvent(TrackingEvent.CopyLink, {});
  };

  return (
    <div onClick={copyLinkToClipboard} title="Share" className="flex items-center space-x-1 cursor-pointer text-secondary-text hover:text-standard-text">
      {copied ? (
        <CheckIcon className="h-5 w-5 text-green-text" />
      ) : (
        <LinkIcon className="h-5 w-5" />
      )}
      <span>{copied ? 'Copied' : 'Share'}</span>
    </div >
  );
}
