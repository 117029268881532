import { Model } from '@/lib/db/model';
import { TimeAgo } from '@/lib/db/time-ago';
import { Expert } from '@/lib/experts/expert';
import { type Message } from './message';

export const NUMBER_OF_MESSAGES_TO_LOAD = 20;

export type ConversationRelevancy = "relevant" | "irrelevant" | "unclear";

export interface Conversation extends Model {
  userId: string,
  sessionId: string,

  expertId: string,

  data: {
    userDisplayName?: string,
    firstInSession: boolean,
  },

  //preloaded data
  expert: Expert,
  messages: Message[],
  sharedConversation?: SharedConversation,
  readOnly?: boolean,

  relevancy: ConversationRelevancy,
  aiScore: number,
  aiEvaluation: string,
  aiSuggestions: string
}

export interface SharedConversation extends Model {
  conversationId: string,
  expertId: string,
  url: string,
  title: string,
  description: string,

  //preloaded data
  createdAtAgo?: TimeAgo,
}


export function newConversation(expert: Expert): Conversation {
  return {
    id: "",
    createdAt: new Date(),
    expertId: expert.id,
    expert,
    messages: [] as Message[],
  } as Conversation;
}
