import { XMarkIcon } from "@heroicons/react/24/outline";
import { MyFile } from "./file-parse-form";


interface FileAttachmentProps {
  file: MyFile;
  onDelete: () => void;
}

/**
 * Helper function to format file sizes (bytes) into a readable string
 * e.g., 1024 -> "1.0 KB", 1048576 -> "1.0 MB"
 */
function formatFileSize(bytes: number): string {
  if (bytes < 1024) {
    return `${bytes} B`;
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(1)} KB`;
  } else if (bytes < 1024 * 1024 * 1024) {
    return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
  } else {
    return `${(bytes / (1024 * 1024 * 1024)).toFixed(1)} GB`;
  }
}

export function FileAttachment({ file, onDelete, }: FileAttachmentProps) {
  const statusColor = file.status === "failed" ? "text-red-text" : "text-secondary-text";

  const renderStatus = () => {
    if (file.status === "uploading") return file.progress;
    if (file.status === "uploaded") return "Uploaded";
    if (file.status === "failed") return "Failed";
    return "";
  };

  return (
    <div className="flex items-start justify-between max-w-60 mt-3 p-3 text-sm border rounded-md border-standard-ring">
      {/* File Info */}
      <div className="shrink flex flex-col mr-3 overflow-hidden">
        <span title={file.file.name} className="font-medium mb-1 truncate">{file.file.name}</span>
        <div className="flex items-center text-xs truncate">
          <div>{formatFileSize(file.file.size)}</div>
          <div className={`ml-2 flex flex-row gap-x-1 items-center ${statusColor}`}>
            {renderStatus()}
            {file.status == 'uploading' && (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )}
          </div>
        </div>
        {file.error && <div title={file.error} className={`mt-1 text-xs truncate ${statusColor}`}>Error: {file.error}</div>}
      </div>

      {/* Delete Button */}
      <button
        type="button"
        onClick={onDelete}
        className="text-tertiary-text hover:text-secondary-text transition-colors"
      >
        <XMarkIcon aria-hidden="true" className="size-5" />
      </button>
    </div>
  );
};
