'use client';

import { NewMessage } from "@/lib/conversations/message";
import { DatabaseSearchResult, SearchResult, WebSearchResult } from "@/lib/courtlistener/search-types";
import { getRefCheckPrompt } from "@/lib/ui/utils";
import { useState } from "react";
import ChatMessageContent from "./chat-message-content";
import { pluralize } from "@/lib/text-utils/pluralize";
import SmallButton from "./small-button";

export function ChatMessageSearchResults({ message }: { message: NewMessage }) {
  const [numVisible, setNumVisible] = useState<number>(3);

  const searchResults = message.metadata?.toolCallsResults?.flatMap(t => t.results).sort((a, b) => (a.rank - b.rank) || (b.relevanceScore - a.relevanceScore)) || [];

  return (
    <>
      <div className="flex flex-col">
        {searchResults.slice(0, numVisible).map((result) => (
          <ChatMessageSearchResult key={ result.type == 'DatabaseSearch' ? result.opinionId : result.url } result={result} />
        ))}
      </div>

      {searchResults.length > numVisible &&
        <a
          onClick={ (e) => setNumVisible(searchResults.length) }
          className="block w-full text-center py-2 hover:underline cursor-pointer rounded-full shadow-sm ring-1 ring-inset ring-standard-ring"
        >
          Show { searchResults.length - numVisible } more documents
        </a>
      }

      { message.metadata?.stats && message.metadata.stats.numDocumentsAnalyzed == 0 && (
        <p>Search did not return any results</p>
      )}

      { message.metadata?.stats && message.metadata.stats.numDocumentsAnalyzed > 0 && (
        <p>
          Processed
          {" "}{ message.metadata.stats.numDocumentsAnalyzed }
          {" "}{ pluralize(message.metadata.stats.numDocumentsAnalyzed, "document") }
          {" "}({(message.metadata.stats.numCharactersAnalyzed / 6).toFixed(0)} words)
          { message.replyTime && (
            <>
              {" "}in{" "}
              {((message.replyTime) / 1000).toFixed(1)} seconds
              - about {(message.metadata.stats.numCharactersAnalyzed / 6 / 250 / 60).toFixed(1)} hours of reading time.
            </>
          )}
        </p>
      )}
    </>
  );
}

function ChatMessageSearchResult( {result}: { result: SearchResult } ) {
  switch (result.type) {
    case 'DatabaseSearch': return (<ChatMessageDatabaseSearchResult result={result} />);
    case 'WebSearch': return (<ChatMessageWebSearchResult result={result} />);
    default: return (<></>);
  }
}

function ChatMessageWebSearchResult( {result}: { result: WebSearchResult } ) {

  return (
    <div className="mt-3 flex flex-col">
      <a href={result.url} target="_blank" className="line-clamp-1" title={result.title}>{result.title}</a>

      <div className="mt-1 text-sm sm:text-xs flex flex-row">
        <div>
          Relevance: {result.relevanceScore !== undefined ? `${(result.relevanceScore * 100).toFixed(1)}%` : 'N/A' }
          { process.env.NODE_ENV == "development" && (
            <> &middot; Subquery: {result.query}</>
          )}
        </div>
      </div>
      <div className="mt-1 text-sm sm:text-xs break-words prose-p:leading-relaxed prose-pre:p-0">
        { result.description }
      </div>
      <div className="text-sm sm:text-xs">
        { result.analysis && <ChatMessageContent content={result.analysis} /> }
      </div>
    </div>
  );
}

function ChatMessageDatabaseSearchResult( {result}: { result: DatabaseSearchResult } ) {
  const checkTreatmentLink = "/research/new?q=" + encodeURIComponent(getRefCheckPrompt(result));

  return (
    <div className="mt-3 flex flex-col">
      <div className="flex flex-row space-x-4">
        <a href={result.absoluteUrl} target="_blank" className="line-clamp-1" title={result.caseName}>{result.caseName}</a>
        <SmallButton href={checkTreatmentLink} target="_blank" caption="Check Treatment" />
      </div>
      <div className="mt-1 text-sm sm:text-xs">
        Relevance: { result.relevanceScore !== undefined ? `${(result.relevanceScore * 100).toFixed(1)}%` : 'N/A' }{process.env.NODE_ENV == 'development' && <> &middot; Subquery: {result.query}</>} &middot; { result.citations.length >= 1 ? result.citations.join(", ") : "[No citation found]" } &middot; { result.citationCount } citing { pluralize(result.citationCount, 'case') } &middot; Filed on: { (result.dateFiled as unknown as string).split('T')[0] }, { result.court }
      </div>
      <div className="text-sm sm:text-xs">
        { result.analysis && <ChatMessageContent content={result.analysis} /> }
      </div>
    </div>
  );
}
