import { Model } from "@/lib/db/model";
import { ToolCalls, ToolCallsResult } from "./conversation-engine";

export enum MessageRole {
  system = "system",
  developer = "developer",
  user = "user",
  assistant = "assistant",
  function = "function",
  data = "data",
  tool = "tool",
}

export interface MessageMetadata {
  tool: "followup-rewriter" | "chain-of-thought" | "full-text-search" | "decider" | "search" | "abort" | "search-result-analyzer" | "usage-limiter" | "usage-guide" | "myfiles" | "recall" | "paralegal",
  toolCalls: ToolCalls,
  toolCallsResults: ToolCallsResult[],
  title: string,
  status: "in-progress" | "done",
  author: "cetient" | "user",
  rewrittenMessage: string,
  stats: {
    numCharactersAnalyzed: number,
    numDocumentsAnalyzed: number,
  }

  fileId?: string,
  fileName?: string,
  fileType: string,
  fileDescription: string,
  fileSize?: number,
  toolCallId?: string, // TODO: this is only recorded by myfiles tool

  recalledMessageId?: string,
  recallStatus?: "success" | "fail",

  useChainOfThought?: boolean,

  loggedIn?: boolean,
}

// Message stored into the database
export interface Message extends Model {
  content: string,
  role: MessageRole,
  conversationId: string,
  inReplyTo?: string,
  metadata: MessageMetadata,
}

// New message that hasn't been saved to the database yet
export type NewMessage = {
  id?: string,
  role: MessageRole;
  content: string,
  metadata?: MessageMetadata,
  replyTime?: number,
}

export type OpenAIMessage = {
  role: MessageRole.assistant | MessageRole.user | MessageRole.system,
  content: string,
  name?: string,
}

export type MessageInHistory = Message & { depth: number };
