'use client';

import { NewMessage } from '@/lib/conversations/message';
import { classNames } from '@/lib/ui/utils';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import ChatMessageContent from './chat-message-content';
import { ChatMessageSearchResults } from './chat-message-search-results';
import ChatMessageUsageGuide from './chat-message-usage-guide';
import CopyButton from './copy-button';
import CopyLinkButton from './copy-link-button';
import FeedbackForm from './feedback-form';
import FormSubmitButton from './form-submit-button';
import ThumbsDownButton from './thumbs-down-button';
import ThumbsUpButton from './thumbs-up-button';


const getMessageStyles = (message: NewMessage) => (
  [
    'py-0',
    'w-fit',
    'prose prose-pre:whitespace-pre-wrap prose-pre:whitespace-break-spaces',
    'sm:prose-sm',
    ...(message.role == 'user' ?
      [
        'px-5',
        'my-2',
        'min-h-10',
        'max-w-9/10',
        'border',
        'border-gray-300 dark:border-neutral-700',
        'bg-green-background text-colored-background-text',
        'rounded-2xl',
        'self-end',
      ] : [
        'self-start',
        'max-w-full',
        'dark:prose-invert',
      ]
    ),
    ...(message.metadata?.tool == "abort" ?
      [
        'px-5',
        'py-2',
        'my-2',
        'border',
        'border-gray-300 dark:border-neutral-700',
        'bg-red-background text-colored-background-text',
        'rounded-2xl',
      ] : []
    ),
    ...(message.metadata?.tool == "usage-guide" ?
      [
        'bg-input-background',
        'px-5 py-2 my-2',
        'rounded-2xl',
      ] : []
    ),
  ].join(' ')
);


type Props = {
  message: NewMessage,
  openOrCloseDocument: (messageId: string) => void,
}

export default function ChatMessage({ message, openOrCloseDocument }: Props) {
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const pathname = usePathname();

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };


  return (
    <div id={message.id} className={getMessageStyles(message)}>
      {
        (message.role == 'user') ?
          <div><p>{message.content}</p></div>
        :
        (message.role == 'tool' && message.metadata?.tool == 'myfiles') ?
          <div
            className="flex flex-row items-stretch cursor-pointer border border-standard-ring rounded-md"
            onClick={(event) => { return openOrCloseDocument(message.id!); }}
          >
            <div className="bg-input-background rounded-l-md p-4 flex items-center">
              { message.metadata?.status == "done" ?
                  <DocumentTextIcon className="inline h-6 w-6" />
                  :
                  <div className="spinner" style={{marginRight: "0"}}></div>
              }
            </div>
            <div className="flex-col py-2 px-4">
              <div className={classNames("break-all font-semibold leading-normal", message.metadata?.status != "done" ? "animate-pulse" : "animate-none")}>
                { message.metadata?.fileName }
              </div>
              <div className="text-xs">Click to open document</div>
            </div>
          </div>
        :
        (message.role == 'tool' && message.metadata?.tool == 'search-result-analyzer') ?
          <div className="">
            <details open={message.metadata?.toolCallsResults && message.metadata.toolCallsResults.flatMap(t=>t.results).length > 0}>
              <summary className={`cursor-pointer ${message.metadata?.status != "done" ? "animate-pulse" : "animate-none"}`}>
                { message.metadata?.title }
              </summary>

              <ChatMessageSearchResults message={message} />
            </details>
          </div>
        :
        (message.role == 'tool' && message.metadata?.tool == 'usage-limiter') ?
          <div className="pb-3">
            <ChatMessageContent content={message.content} />
            <div className="flex flex-wrap gap-2">
              <Link prefetch={false} href="/user/plan">
                <FormSubmitButton caption="See Pricing Plans and Upgrade" isDisabled={false} isSaving={false} buttonType="button" />
              </Link>
              {!message.metadata?.loggedIn && (
                <Link prefetch={false} href={`/auth/signin?callbackUrl=${encodeURIComponent(pathname)}`}>
                  <FormSubmitButton caption="Already Purchased Pro? Please Sign In" isDisabled={false} isSaving={false} buttonType="button" />
                </Link>
              )}
            </div>
          </div>
        :
        (message.role == 'tool' && message.metadata?.tool == 'usage-guide') && !message.content ?
          <div className={`${message.metadata?.status != "done" ? "animate-pulse" : "animate-none"}`}>
            { "Thinking of how to help you..." }
          </div>
        :
        (message.role == 'tool' && message.metadata?.tool == 'usage-guide') ?
          <ChatMessageUsageGuide message={message} />
        :
        (message.role == 'tool') ?
          <div className="">
            { message.content && (
              <details open={false}>
                <summary
                  className={classNames(
                    "cursor-pointer",
                    message.metadata?.status != "done" ? "animate-pulse" : "animate-none"
                  )}
                >
                  { message.metadata?.title }
                </summary>
                <div className="border-l-2 border-standard-ring pl-2 opacity-60">
                  <ChatMessageContent content={message.content} />
                </div>
              </details>
            )}
            { !message.content && <span className={classNames(message.metadata?.status != "done" ? "animate-pulse" : "animate-none")}>{ message.metadata?.title }</span> }
          </div>
        :
        (message.role == 'assistant' && !message.content) ?
          <div className={`${message.metadata?.status != "done" ? "animate-pulse" : "animate-none"}`}>
            { "..." }
          </div>
        :
          <div id={message.id} className="flex flex-col space-y-1">

            <ChatMessageContent content={message.content} />

            { message.replyTime && (
              <>
                <div className="flex space-x-4 print:hidden">
                  <CopyLinkButton />
                  <CopyButton text={message.content} />
                  <ThumbsUpButton messageId={message.id} />
                  <ThumbsDownButton messageId={message.id} onClick={toggleFormVisibility} />
                </div>
                <FeedbackForm visible={isFormVisible} toggleVisibility={toggleFormVisibility} messageId={message.id} />
              </>
            )}
          </div>
      }
    </div>
  );
}
